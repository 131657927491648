import Navbar from "./components/Navbar";
import "./Leaderboard.css";
import { useEffect, useState, createRef } from "react";
import axiosInstance from "./components/axiosConfig";
import Toast from "./components/Toast";
import {createFileName, useScreenshot} from "use-react-screenshot";
import ShareIcon from "@mui/icons-material/Share";

function Leaderboard() {
  const [toast, setToast] = useState(null);

  const [leaderboard, setLeaderboard] = useState([]);
  const [tab, setTab] = useState(1);
  const [group, setGroup] = useState(1);
  const [filter, setFilter] = useState("total");
  const [dataLoaded, setDataLoaded] = useState(false);
  let affiliateLeaderboard = [];
  let teamLeaderboard = [];
  let organizationLeaderboard = [];

  const [affilLeaderboard, setAffilLeaderboard] = useState([]);
  const [affilWater, setAffilWater] = useState([]);
  const [affilCO2, setAffilCO2] = useState([]);
  const [affilLand, setAffilLand] = useState([]);
  const [affilCholesterol, setAffilCholesterol] = useState([]);
  const [affilFoodWaste, setAffilFoodWaste] = useState([]);
  let affiliateWaterLeaderboard = [];
  let affiliateCO2Leaderboard = [];
  let affiliateLandLeaderboard = [];
  let affiliateCholesterolLeaderboard = [];
  let affiliateFoodWasteLeaderboard = [];

  const [teaLeaderboard, setTeaLeaderboard] = useState([]);
  const [teaWater, setTeaWater] = useState([]);
  const [teaCO2, setTeaCO2] = useState([]);
  const [teaLand, setTeaLand] = useState([]);
  const [teaCholesterol, setTeaCholesterol] = useState([]);
  const [teaFoodWaste, setTeaFoodWaste] = useState([]);

  let teamWaterLeaderboard = [];
  let teamCO2Leaderboard = [];
  let teamLandLeaderboard = [];
  let teamCholesterolLeaderboard = [];
  let teamFoodWasteLeaderboard = [];

  const [orgLeaderboard, setOrgLeaderboard] = useState([]);
  const [orgWater, setOrgWater] = useState([]);
  const [orgCO2, setOrgCO2] = useState([]);
  const [orgLand, setOrgLand] = useState([]);
  const [orgCholesterol, setOrgCholesterol] = useState([]);
  const [orgFoodWaste, setOrgFoodWaste] = useState([]);

  let organizationWaterLeaderboard = [];
  let organizationCO2Leaderboard = [];
  let organizationLandLeaderboard = [];
  let organizationCholesterolLeaderboard = [];
  let organizationFoodWasteLeaderboard = [];

  const [globalCarbonChange, setGlobalCarbonChange] = useState(0);
  const [globalCholesterolChange, setglobalCholesterolChange] = useState(0);
  const [globalLandChange, setGlobalLandChange] = useState(0);
  const [globalFoodChange, setGlobalFoodChange] = useState(0);
  const [globalWaterChange, setGlobalWaterChange] = useState(0);

  const ref = createRef(null);


  useEffect(() => {
    //Runs on page first load to set global statistics
    try {
      getGlobalStatistics();
    } catch (error) {
      console.log(error);
    }
  }, []);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  async function getGlobalStatistics() {
    try {
      // WE need to implement a backend api call to get these numbers, right now they are randomized
      const response = await axiosInstance.get("/globalstats");

      if (response == null) {
        console.log("Null response");
        setGlobalCarbonChange(getRandomInt(0, 99));
        setGlobalWaterChange(getRandomInt(0, 99));
        setglobalCholesterolChange(getRandomInt(0, 99));
        setGlobalFoodChange(getRandomInt(0, 99));
        setGlobalLandChange(getRandomInt(0, 99));
        return;
      } else {
        //Gets and sets variables according to api call, preferrrably shud be percentage
        setGlobalCarbonChange(response.data.carbon);
        setGlobalWaterChange(response.data.water);
        setglobalCholesterolChange(response.data.cholesterol);
        setGlobalFoodChange(response.data.food);
        setGlobalLandChange(response.data.land);
      }
    } catch (error) {
      setGlobalCarbonChange(getRandomInt(0, 99));
      setGlobalWaterChange(getRandomInt(0, 99));
      setglobalCholesterolChange(getRandomInt(0, 99));
      setGlobalFoodChange(getRandomInt(0, 99));
      setGlobalLandChange(getRandomInt(0, 99));

      console.error(error);
    }
  }

  async function getLeaderboardInfo() {
    if (dataLoaded) return;
    try {
      const response = await axiosInstance.get("/affiliate");
      if (response == null) {
        console.log("Null response");
        const errorToast = (
          <Toast errorMessage="Affiliate with the same email found" />
        );
        setToast(errorToast);
        // automatically close the toast
        setTimeout(() => {
          setToast(null);
        }, 7000);

        return;
      }

      // console.log(response.data);
      affiliateLeaderboard = [];
      teamLeaderboard = [];
      organizationLeaderboard = [];

      for (let i = 0; i < response.data.length; i++) {
        let impact = cleanData(response.data[i]);

        let newItem = {
          name: response.data[i].organizationName,
          total: impact.sumOfEnvirImpact,
          totalCarsOffRoad: impact.totalCarsOffRoad,
          totalFightingFoodWaste: impact.totalFightingFoodWaste,
          totalWaterSaved: impact.totalWaterSaved,
          totalLandUse: impact.totalLandUse,
          totalCholesterolSaved: impact.totalCholesterolSaved,
          profilePicture: impact.profilePicture,
        };
        let affiliateClassificationName = "Affiliate";
        if (
          response.data[i].affiliateType !== undefined &&
          response.data[i].affiliateType !== null
        ) {
          affiliateClassificationName = response.data[i].affiliateType.name;
        }

        if (affiliateClassificationName === "Organization") {
          organizationLeaderboard.push(newItem);
        } else if (affiliateClassificationName === "Team") {
          teamLeaderboard.push(newItem);
        } else if (affiliateClassificationName === "Affiliate") {
          affiliateLeaderboard.push(newItem);
        } else {
          console.log("Error: Affiliate Classification Name is not valid");
          affiliateLeaderboard.push(newItem);
        }
      }

      affiliateLeaderboard.sort((a, b) => b.total - a.total);
      teamLeaderboard.sort((a, b) => b.total - a.total);
      organizationLeaderboard.sort((a, b) => b.total - a.total);

      createSubLeaderboardArrays();

      setDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }

  const cleanData = (data) => {
    if (data.affiliateClimateChangeImpact == null) {
      return {
        totalCarsOffRoad: 0,
        totalFightingFoodWaste: 0,
        totalWaterSaved: 0,
        totalLandUse: 0,
        totalCholesterolSaved: 0,
        sumOfEnvirImpact: 0,
        profilePicture: "profileChoice1",
      };
    }
    let totalCarsOffRoad = data.affiliateClimateChangeImpact.totalCarsOffRoad;
    if (totalCarsOffRoad == null) {
      totalCarsOffRoad = 0;
    }
    let totalFightingFoodWaste =
      data.affiliateClimateChangeImpact.totalFightingFoodWaste;
    if (totalFightingFoodWaste == null) {
      totalFightingFoodWaste = 0;
    }
    let totalWaterSaved = data.affiliateClimateChangeImpact.totalWaterSaved;
    if (totalWaterSaved == null) {
      totalWaterSaved = 0;
    }
    let totalLandUse = data.affiliateClimateChangeImpact.totalLandUse;
    if (totalLandUse == null) {
      totalLandUse = 0;
    }
    let totalCholesterolSaved =
      data.affiliateClimateChangeImpact.totalCholesterolSaved;
    if (totalCholesterolSaved == null) {
      totalCholesterolSaved = 0;
    }

    let sumOfEnvirImpact =
      totalCarsOffRoad +
      totalFightingFoodWaste +
      totalWaterSaved +
      totalLandUse +
      totalCholesterolSaved;
    sumOfEnvirImpact = Math.floor(sumOfEnvirImpact / 10);

    let profilePicture = "profileChoice1";
    if (data.profilePhoto !== undefined && data.profilePhoto !== null) {
      profilePicture = data.profilePhoto;
      if (Number.isInteger(profilePicture)) {
        if (profilePicture === 1) {
          profilePicture = "profileChoice1";
        } else if (profilePicture === 2) {
          profilePicture = "profileChoice2";
        } else if (profilePicture === 3) {
          profilePicture = "profileChoice3";
        } else {
          profilePicture = "profileChoice1";
        }
      }
    }
    return {
      totalCarsOffRoad,
      totalFightingFoodWaste,
      totalWaterSaved,
      totalLandUse,
      totalCholesterolSaved,
      sumOfEnvirImpact,
      profilePicture,
    };
  };

  const createSubLeaderboardArrays = () => {
    affiliateWaterLeaderboard = [];
    affiliateCO2Leaderboard = [];
    affiliateLandLeaderboard = [];
    affiliateCholesterolLeaderboard = [];
    affiliateFoodWasteLeaderboard = [];
    teamWaterLeaderboard = [];
    teamCO2Leaderboard = [];
    teamLandLeaderboard = [];
    teamCholesterolLeaderboard = [];
    teamFoodWasteLeaderboard = [];
    organizationWaterLeaderboard = [];
    organizationCO2Leaderboard = [];
    organizationLandLeaderboard = [];
    organizationCholesterolLeaderboard = [];
    organizationFoodWasteLeaderboard = [];
    //For affiliates
    for (let index = 0; index < affiliateLeaderboard.length; index++) {
      const element = affiliateLeaderboard[index];
      affiliateWaterLeaderboard.push({
        name: element.name,
        total: element.totalWaterSaved,
        profilePicture: element.profilePicture,
      });
      affiliateCO2Leaderboard.push({
        name: element.name,
        total: element.totalCarsOffRoad,
        profilePicture: element.profilePicture,
      });
      affiliateLandLeaderboard.push({
        name: element.name,
        total: element.totalLandUse,
        profilePicture: element.profilePicture,
      });
      affiliateCholesterolLeaderboard.push({
        name: element.name,
        total: element.totalCholesterolSaved,
        profilePicture: element.profilePicture,
      });
      affiliateFoodWasteLeaderboard.push({
        name: element.name,
        total: element.totalFightingFoodWaste,
        profilePicture: element.profilePicture,
      });
    }

    affiliateWaterLeaderboard.sort((a, b) => b.total - a.total);
    affiliateCO2Leaderboard.sort((a, b) => b.total - a.total);
    affiliateLandLeaderboard.sort((a, b) => b.total - a.total);
    affiliateCholesterolLeaderboard.sort((a, b) => b.total - a.total);
    affiliateFoodWasteLeaderboard.sort((a, b) => b.total - a.total);
    setAffilWater(affiliateWaterLeaderboard);
    setAffilCO2(affiliateCO2Leaderboard);
    setAffilLand(affiliateLandLeaderboard);
    setAffilCholesterol(affiliateCholesterolLeaderboard);
    setAffilFoodWaste(affiliateFoodWasteLeaderboard);
    setAffilLeaderboard(affiliateLeaderboard);
    //For team
    for (let index = 0; index < teamLeaderboard.length; index++) {
      const element = teamLeaderboard[index];
      teamWaterLeaderboard.push({
        name: element.name,
        total: element.totalWaterSaved,
        profilePicture: element.profilePicture,
      });
      teamCO2Leaderboard.push({
        name: element.name,
        total: element.totalCarsOffRoad,
        profilePicture: element.profilePicture,
      });
      teamLandLeaderboard.push({
        name: element.name,
        total: element.totalLandUse,
        profilePicture: element.profilePicture,
      });
      teamCholesterolLeaderboard.push({
        name: element.name,
        total: element.totalCholesterolSaved,
        profilePicture: element.profilePicture,
      });
      teamFoodWasteLeaderboard.push({
        name: element.name,
        total: element.totalFightingFoodWaste,
        profilePicture: element.profilePicture,
      });
    }
    teamWaterLeaderboard.sort((a, b) => b.total - a.total);
    teamCO2Leaderboard.sort((a, b) => b.total - a.total);
    teamLandLeaderboard.sort((a, b) => b.total - a.total);
    teamCholesterolLeaderboard.sort((a, b) => b.total - a.total);
    teamFoodWasteLeaderboard.sort((a, b) => b.total - a.total);
    setTeaWater(teamWaterLeaderboard);
    setTeaCO2(teamCO2Leaderboard);
    setTeaLand(teamLandLeaderboard);
    setTeaCholesterol(teamCholesterolLeaderboard);
    setTeaFoodWaste(teamFoodWasteLeaderboard);
    setTeaLeaderboard(teamLeaderboard);
    //For organization
    for (let index = 0; index < organizationLeaderboard.length; index++) {
      const element = organizationLeaderboard[index];
      organizationWaterLeaderboard.push({
        name: element.name,
        total: element.totalWaterSaved,
        profilePicture: element.profilePicture,
      });
      organizationCO2Leaderboard.push({
        name: element.name,
        total: element.totalCarsOffRoad,
        profilePicture: element.profilePicture,
      });
      organizationLandLeaderboard.push({
        name: element.name,
        total: element.totalLandUse,
        profilePicture: element.profilePicture,
      });
      organizationCholesterolLeaderboard.push({
        name: element.name,
        total: element.totalCholesterolSaved,
        profilePicture: element.profilePicture,
      });
      organizationFoodWasteLeaderboard.push({
        name: element.name,
        total: element.totalFightingFoodWaste,
        profilePicture: element.profilePicture,
      });
    }
    organizationWaterLeaderboard.sort((a, b) => b.total - a.total);
    organizationCO2Leaderboard.sort((a, b) => b.total - a.total);
    organizationLandLeaderboard.sort((a, b) => b.total - a.total);
    organizationCholesterolLeaderboard.sort((a, b) => b.total - a.total);
    organizationFoodWasteLeaderboard.sort((a, b) => b.total - a.total);
    setOrgWater(organizationWaterLeaderboard);
    setOrgCO2(organizationCO2Leaderboard);
    setOrgLand(organizationLandLeaderboard);
    setOrgCholesterol(organizationCholesterolLeaderboard);
    setOrgFoodWaste(organizationFoodWasteLeaderboard);
    setOrgLeaderboard(organizationLeaderboard);
  };

  const generateLeaderboard = (lead) => {
    // console.log(lead);
    if (lead.length === 0 && !dataLoaded) {
      return (
        <div
          role="status"
          className="flex flex-col items-center justify-center"
        >
          <svg
            class="mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#2EC5D6]"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      );
    } else if (lead.length === 0 && dataLoaded) {
      if (lead.length < 6 && group === 1) {
        lead = [...affilLeaderboard];
      } else {
        for (let i = 0; i < 6; i++) {
          lead.push({
            name: "Join Us",
            total: 0,
            profilePicture: `profileChoice${(i % 3) + 1}`,
          });
        }
      }
      // }
    }
    if (lead.length < 6) {
      for (let i = 0; i < 6; i++) {
        lead.push({
          name: "Join Us",
          total: 0,
          profilePicture: `profileChoice${(i % 3) + 1}`,
        });
      }
    }
    let contentToGenerate = [];
    contentToGenerate.push(
      <tr>
        <th>Rank</th>
        <th></th>
        <th>Name</th>
        <th>{filter === "co2" ? "CHICKEN" : filter.charAt(0).toUpperCase() + filter.slice(1)}</th>
      </tr>
    );
    for (let i = 0; i < lead.length; i++) {
      contentToGenerate.push(
        <tr>
          <td>{i + 1}</td>
          <td>
            <img
              src={`./profileicons/${lead[i].profilePicture}.svg`}
              alt="profile"
            />
          </td>
          <td className="nameImgContainer">{lead[i].name}</td>
          <td>{lead[i].total}</td>
        </tr>
      );
    }
    return <table>{contentToGenerate}</table>;
  };

  const changeTab = (newTab) => {
    setTab(newTab);
  };

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  useEffect(() => {
    // console.log("Filter has changed to:  " + filter);
    figureOutWhatNewLeaderboardIs();
  }, [filter]);

  useEffect(() => {
    // console.log("Group has changed to: " + group); //1 is affiliate, 2 is team, 3 is  organization
    figureOutWhatNewLeaderboardIs();
  }, [group]);

  useEffect(() => {
    getLeaderboardInfo();
  }, []);
  const figureOutWhatNewLeaderboardIs = () => {
    if (group === 1) {
      if (filter === "cholesterol") {
        setLeaderboard(affilCholesterol);
      } else if (filter === "water") {
        setLeaderboard(affilWater);
      } else if (filter === "co2") {
        setLeaderboard(affilCO2);
      } else if (filter === "waste") {
        setLeaderboard(affilFoodWaste);
      } else if (filter === "land") {
        setLeaderboard(affilLand);
      } else if (filter === "total") {
        setLeaderboard(affilLeaderboard);
      } else {
        console.log(
          "Error:  Something went wrong with the leaderboard filter."
        );
        setLeaderboard(affilLeaderboard);
      }
    } else if (group === 2) {
      if (filter === "cholesterol") {
        setLeaderboard(teaCholesterol);
      } else if (filter === "water") {
        setLeaderboard(teaWater);
      } else if (filter === "co2") {
        setLeaderboard(teaCO2);
      } else if (filter === "waste") {
        setLeaderboard(teaFoodWaste);
      } else if (filter === "land") {
        setLeaderboard(teaLand);
      } else if (filter === "total") {
        setLeaderboard(teaLeaderboard);
      } else {
        console.log(
          "Error:  Something went wrong with the leaderboard filter."
        );
        setLeaderboard(teaLeaderboard);
      }
    } else if (group === 3) {
      if (filter === "cholesterol") {
        setLeaderboard(orgCholesterol);
      } else if (filter === "water") {
        setLeaderboard(orgWater);
      } else if (filter === "co2") {
        setLeaderboard(orgCO2);
      } else if (filter === "waste") {
        setLeaderboard(orgFoodWaste);
      } else if (filter === "land") {
        setLeaderboard(orgLand);
      } else if (filter === "total") {
        setLeaderboard(orgLeaderboard);
      } else {
        console.log(
          "Error:  Something went wrong with the leaderboard filter."
        );
        setLeaderboard(orgLeaderboard);
      }
    } else {
      setLeaderboard(affilLeaderboard);
    }
  };

  return (
    <div className="leaderboard" ref={ref}>
      <Navbar />
      <div className="leaderboard-controller">
        {/* Turn this into a component*/}
        <span className="lead-title">Climate Leaderboard</span>
        <div id="btn-container">
          <div id="section-btn-container">
            <button
              className={tab === 1 ? "section-btn selected" : "section-btn"}
              onClick={() => changeTab(1)}
            >
              Month
            </button>
            <button
              className={tab === 2 ? "section-btn selected" : "section-btn"}
              onClick={() => changeTab(2)}
            >
              Year
            </button>
            <button
              className={tab === 3 ? "section-btn selected" : "section-btn"}
              onClick={() => changeTab(3)}
            >
              All Time
            </button>
          </div>
          <select
            className="dropdwn"
            id="section"
            onChange={(e) => {
              changeTab(e.target.value);
            }}
          >
            <option value="1">Month</option>
            <option value="2">Year</option>
            <option value="3">All Time</option>
          </select>
          <select
            className="dropdwn"
            id="filter"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          >
            <option value="water">Water</option>
            <option value="co2">Chicken</option>
            <option value="waste">Food Waste</option>
            <option value="land">Land Use</option>
            <option value="cholesterol">Cholesterol</option>
            <option value="total" selected="selected">
              Total
            </option>
          </select>
          <select
            className="dropdwn"
            id="group"
            onChange={(e) => {
              setGroup(e.target.value);
            }}
          >
            <option value="1">Affiliate</option>
            <option value="2">Team</option>
            <option value="3">Organization</option>
          </select>
          <div id="section-btn-container">
            <button
              className={group === 1 ? "section-btn selected" : "section-btn"}
              onClick={() => setGroup(1)}
            >
              Affiliate
            </button>
            <button
              className={group === 2 ? "section-btn selected" : "section-btn"}
              onClick={() => setGroup(2)}
            >
              Team
            </button>
            <button
              className={group === 3 ? "section-btn selected" : "section-btn"}
              onClick={() => setGroup(3)}
            >
              Organization
            </button>
            <button onClick={downloadScreenshot}>
              <ShareIcon></ShareIcon>
            </button>
          </div>
        </div>
        <div id="leaderboard-container">{generateLeaderboard(leaderboard)}</div>
      </div>
    </div>
  );
}

export default Leaderboard;
