import React from "react";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import "./CircProgress.css";
export default function CircProgress(props) {
  return (
    <div style={{ width: "10%", height: "40%" }} className="gauge">
      <CircularProgressbarWithChildren
        value={props.prog}
        styles={buildStyles({
            trailColor: props.trailColor,
        })}
      >
        <img
          style={{ width: "50%", marginTop: 0 }}
          src={props.pic}
          alt="progress of climate goal"
        />
        {props.text ? (
          <span className={"prog-text"}> {props.text} </span>
        ) : (
          <span></span>
        )}
      </CircularProgressbarWithChildren>
      <div className="guage-shadow"></div>
    </div>
  );
}
