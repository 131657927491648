import Navbar from "../components/Navbar";
import React, { useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "../components/axiosConfig";


export default function AffiliateLogin() {
    const navigate = useNavigate()
    const emailRef = useRef(null)
    const passwordRef = useRef(null)


    async function handleAffiliateLogin(e){
        e.preventDefault();
        console.log(emailRef.current.value)
        console.log(passwordRef.current.value)
        await axiosInstance.post(`/affiliate/login`, {
                emailAddress: emailRef.current.value,
                password: passwordRef.current.value
            })
            .then((response)=>{
                console.log(response)
                navigate("/affiliate/transaction-list")
            })
            .catch(()=>{
                alert("Incorrect username or password")
            })
    }


    return (
        <div className="affiliateLogin">
            <Navbar/>
            {/*component*/}
            <body className="font-mono bg-ulivit-background-teal">
            {/*container*/}
            <div className="container mx-auto">
                <div className="flex justify-center px-6 pt-20">
                    {/*row*/}
                    <div className="w-full xl:w-3/4 lg:w-11/12 flex">
                        {/*col*/}
                        <div
                            className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
                            style={
                                //this needs to be updated to use images provided by Ulivit
                                {backgroundImage: 'url(https://images.unsplash.com/photo-1511690656952-34342bb7c2f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80)'}
                            }
                        ></div>
                        {/*col*/}
                        <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
                            <h3 className="pt-4 text-3xl text-center font-roadrage tracking-normal">Join Us to Change the World!</h3>
                            <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                                <div className="mb-4">
                                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        ref={emailRef}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-bold text-gray-700" htmlFor="password">
                                        Password
                                    </label>
                                    <input className={`w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
                                           id="password"
                                           type="password"
                                           placeholder="Please Enter Your Password"
                                           ref={passwordRef}
                                    />
                                </div>
                
                                <div className="mb-6 text-center">
                                    <button className="w-full px-4 py-2 font-bold text-white bg-ulivit-background-teal rounded-full hover:bg-teal-400 focus:outline-none focus:shadow-outline"
                                        onClick={(e)=>handleAffiliateLogin(e)}>
                                        Sign In
                                    </button>
                                    {/*<Link*/}

                                    {/*    // to = {`/affiliate/transaction-list`}*/}
                                    {/*>*/}
                                    {/*</Link>*/}
                                </div>
                                <hr className="mb-6 border-t"/>
                                <div className="text-center">
                                    <Link
                                        className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                        to= {`/forgot-password`}
                                    >
                                        Forgot Password?
                                    </Link>
                                </div>
                                <div className="text-center">
                                    <Link
                                        className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                        to= {`/affiliate-registration`}
                                    >
                                        Don't have an account? Sign up 
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </body>
        </div>
    )
}