import React, { Component } from "react";
import "./AddMatrix.css";
import axiosInstance from "../components/axiosConfig";

/*product matrices*/
const initState = {
  sku: "",
  waterSaved: "",
  carsOffRoad: "",
  landUse: "",
  fightingFoodWaste: "",
  cholesterolSaved: "",
};

/*add matrix component*/
class AddMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  save = async (e) => {
    e.preventDefault();
    const {
      sku,
      waterSaved,
      carsOffRoad,
      landUse,
      fightingFoodWaste,
      cholesterolSaved,
    } = this.state;

    if (!sku) {
      return this.setState({
        flash: { status: "is-danger", msg: "*Please enter SKU!" },
      })
    }

    if (isNaN(waterSaved) || isNaN(carsOffRoad) || isNaN(landUse) || isNaN(fightingFoodWaste) || isNaN(cholesterolSaved)) {
      return this.setState({
        flash: { status: "is-danger", msg: "*Measure must be number!" },
      })
    }

    if (waterSaved.length === 0 || carsOffRoad.length === 0 || landUse.length === 0 || fightingFoodWaste.length === 0 || cholesterolSaved.length === 0) {
      return this.setState({
        flash: { status: "is-danger", msg: "*Measure cannot be null!" },
      })
    }

    axiosInstance
      .post('/climate-measure', {
        sku,
        waterSaved,
        carsOffRoad,
        landUse,
        fightingFoodWaste,
        cholesterolSaved,
      })
    this.props.toggle();
  };
  /*error message handler*/
  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, error: "" });

  // handleSKU = (e) =>
  //   this.setState({ [e.target.name]: e.target.value, error: "" });

  // handle close
  handleClick = () => {
    this.props.toggle();
  };

  render() {
    const {
      sku,
      waterSaved,
      carsOffRoad,
      landUse,
      fightingFoodWaste,
      cholesterolSaved,
    } = this.state;

    return (
      <div className="modal">
        <div className="modal_content">
          <span className="close" onClick={this.handleClick}>
            &times;
          </span>
          <br />
          <br />
          <div className="form-logo">
            <img
              src="/logo.png"
              className="log"
              alt="uliviit logo(plant based protein)"
            ></img>
          </div>
          <div className="form-heading">ulivit</div>

          <form onSubmit={this.save} className="popup-form" >
            <div className="field">
              <input
                className="input"
                type="text"
                name="sku"
                defaultValue={sku}
                onChange={this.handleChange}
                required
              />
              <label className="label">Product SKU : </label>
            </div>
            <div className="field">
              <input
                className="input"
                type="text"
                name="waterSaved"
                min="0"
                defaultValue={waterSaved}
                onChange={this.handleChange}
                required
              />
              <label className="label">Water saved (Litres): </label>
            </div>
            <div className="field">
              <input
                className="input"
                type="text"
                name="carsOffRoad"
                min="0"
                defaultValue={carsOffRoad}
                onChange={this.handleChange}
                required
              />
              <label className="label">Chicken saved (Per Egg) : </label>
            </div>
            <div className="field">
              <input
                className="input"
                type="text"
                name="landUse"
                min="0"
                defaultValue={landUse}
                onChange={this.handleChange}
                required
              />
              <label className="label">Land saved (Metres Squared) : </label>
            </div>
            <div className="field">
              <input
                className="input"
                type="text"
                name="fightingFoodWaste"
                min="0"
                defaultValue={fightingFoodWaste}
                onChange={this.handleChange}
                required
              />
              <label className="label">Meals saved (Grams): </label>
            </div>
            <div className="field">
              <input
                className="input"
                type="text"
                name="cholesterolSaved"
                min="0"
                defaultValue={cholesterolSaved}
                onChange={this.handleChange}
                required
              />
              <label className="label">Cholesterol saved (Milligram): </label>
            </div>
            {this.state.flash && (
              <div className="flash">
                <div className={`notification ${this.state.flash.status}`}>
                  {this.state.flash.msg}
                </div>
              </div>
            )}
            <div className="field is-clearfix">
              <div className="button-container">
                <button className="action-button" type="submit" onClick={this.save}>
                  Submit
                </button>
                <button className="action-button cancel-button" type="button" onClick={this.handleClick}>
                  Cancel
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    );
  }
}
export default AddMatrix;
