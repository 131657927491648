import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Leaderboard from "./Leaderboard";
import reportWebVitals from "./reportWebVitals";
import MatricesList from "./views/MatricesList";
import AdminManage from "./AdminManage";
import AdminLogin from "./AdminLogin";
import AdminGlobal from "./AdminGlobal";
import { AuthProvider } from "./components/AuthContext";
import ProtectedRoutes from "./components/ProtectedRoutes";
import AffiliateRegistration from "./views/AffiliateRegistration";
import AffiliatesList from "./views/AffiliatesList";
import AffiliateLogin from "./views/AffiliateLogin";
import AffiliateTransactionList from "./views/AffiliateTransactionList";
import ThankYou from "./views/ThankYou";
import ForgotPassword from "./views/ForgotPassword";
import Confirmation from "./views/Confirmation";

// import RowEditControlGrid from "./views/test"

//React Router imports
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TermsOfService from "./views/TermsOfService";
import axiosInstance from "./components/axiosConfig";
//Declaring routes

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <App />,
  },
  {
    path: "leaderboard",
    element: <Leaderboard />,
  },
  {
    path: "admin-login",
    element: <AdminLogin />,
  },
  {
    path: "affiliate-registration",
    element: <AffiliateRegistration />,
  },
  {
    path: "affiliate-login",
    element: <AffiliateLogin />,
  },
  {
    path: "thankyou",
    element: <ThankYou />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "confirmation",
    element: <Confirmation />,
  },
  {
    path: "affiliate/transaction-list",
    element: <AffiliateTransactionList />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfService />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "admin/matrices",
        element: <MatricesList />,
      },
      {
        path: "admin/affiliates",
        element: <AffiliatesList />,
      },
      {
        path: "admin/add-admin",
        element: <AdminManage />,
      },
      {
        path: "admin/global",
        element: <AdminGlobal />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
