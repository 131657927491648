import "./AdminNavbar.css";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
export default function AdminNavbar() {
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="admin-nav-main">
      <Link to={`/admin/matrices`}>Matrices List</Link>
      {/* <Link to={`/admin/add`}>Add Matrix</Link> */}
      <Link to={`/admin/add-admin`}>Admin Management</Link>
      <Link to={`/admin/global`}>Global Stats</Link>
      <Link to={`/admin/affiliates`}>Affiliate Management</Link>
      <Link to={`/`} onClick={handleLogout}>
        Log Out
      </Link>
    </div>
  );
}
