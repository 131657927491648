import Navbar from "../components/Navbar";
import React, { useState, useRef, Component } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';

export default function ForgotPassword() {
    const emailRef = useRef(null)
    const [emailBorderColor, setEmailBorderColor] = useState("border-gray-200")
    function validateEmail(){
        if (!emailRef.current.value){
            setEmailBorderColor("border-red-400")
        }
        // also highlight the border if email is not in valid email format
        else if (!emailRef.current.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            setEmailBorderColor("border-red-400")
        }
        else if (emailRef.current.value){
            setEmailBorderColor("border-gray-200")
        }

    }

    return (
        <div className="forgotPassword">
            <Navbar/>
            {/*component*/}
            <body class="font-mono bg-ulivit-background-teal">
            {/*container*/}
            <div class="container mx-auto">
                <div class="flex justify-center px-6 pt-20">
                    {/*row*/}
                    <div class="w-full xl:w-3/4 lg:w-11/12 flex">
                        {/*col*/}
                        <div
                            class="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
                            style={
                                //this needs to be updated to use images provided by Ulivit
                                {backgroundImage: 'url(https://images.unsplash.com/photo-1511690656952-34342bb7c2f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80)'}
                            }
                        ></div>
                        {/*col*/}
                        <div class="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
                            <div class="mb-6 text-center">
                            <EmailIcon sx={{ fontSize: 100 }}/>
                            </div>
                            <h1 class="pt-4 text-4xl text-center font-roadrage tracking-normal">Check your email</h1>
                            <br></br>
                            <h3 class="pt-4 text-2xl text-center font-roadrage tracking-normal">we sent a password reset link to your email</h3>
                            <br></br><br></br>
                            <div class="mb-6 text-center">
                                    <Link
                                        class="w-full px-4 py-2"
                                        to= {`/affiliate-login`}
                                    >
                                    <ArrowBackIcon fontSize="small"/>
                                    <span>&nbsp;&nbsp;</span>
                                    Back to log in
                                    </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </body>
        </div>
    )
}