// axiosConfig.js
import axios from "axios";

import * as jose from 'jose'


// Create an instantiable axios object
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// Helper function to create a JWT
const createJWT = async () => {
    const payload = {
        name: 'Service Account',
        exp: Date.now() + 30 * 60 * 1000,
    };

    const secret = new TextEncoder().encode(process.env.REACT_APP_IMPACT_CALCULATOR_CLIENT_SECRET);

    const alg = 'HS256';
    const jwt = await new jose.SignJWT(payload).setProtectedHeader({ alg }).sign(secret).then(
        (resolve) => {return resolve;}
    )

    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
};

createJWT();

export default axiosInstance;
