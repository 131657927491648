import React, { useState, useEffect } from 'react';
import Navbar from './components/AdminNavbar';
import "./AdminManage.css";
import axiosInstance from "./components/axiosConfig";
import './AdminManage.css';
import { DataGrid } from '@mui/x-data-grid';

export default function AdminManage() {
  const [admins, setAdmins] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    const response = await axiosInstance.get('/admin');
    setAdmins(response.data);
  };

  const registerAdmin = async () => {
    await axiosInstance.post('/admin', {
      username,
      password,
      emailAddress,
    });
    fetchAdmins();
  };

  const deleteAdmin = async (id) => {
    await axiosInstance.delete(`/admin/id=${id}`);
    fetchAdmins();
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'password', headerName: 'Password', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <button
          onClick={() => deleteAdmin(params.id)}
          className="admin-management-button"
        >
          Delete
        </button>
      ),
    },
  ];

  return (
    <div className="admin-management">
      <Navbar />
      <div className="admin-management-content">
        <h1>Admin  Management</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            className="admin-search-bar"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="admin-search-bar"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={registerAdmin} className="admin-management-button">
            Register Admin
          </button>
        </form>
        <h2>Admins List</h2>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={admins}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
}
