import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import "react-circular-progressbar/dist/styles.css";
import ImpactSearch from "./components/ImpactSearch";
import axiosInstance from "./components/axiosConfig";


export default function App() {

  const getImpactData = async (isPromoCode, value) => {
    let url;
    if (isPromoCode) {
      url = `/affiliate_order_impact/promoCode=${value}`;
    } else {
      url = `/affiliate_order_impact/orderId=${value}`;
    }

    if (!axiosInstance.defaults.headers.common['Authorization']) {
      await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100ms
    }

    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };


  const closePopup = () => {
    document.getElementsByClassName("affiliate-promp")[0].style.opacity = 0;
    window.setTimeout(() => {
      document.getElementsByClassName("affiliate-promp")[0].style.display =
        "none";
    }, 500);
  };

return (
  <div className="App">
    <div className="home-header">
      <Navbar />
    </div>
    <div className="main-page-content">
      <div className="dynamic">
        <ImpactSearch getImpactData={getImpactData} />
      </div>
    </div>

    <div className="affiliate-promp">
      <span className="close-btn" onClick={closePopup}>
        x
      </span>
      <span className="affiliate-promp-text">Join Us!</span>
      <a href="/affiliate-registration">
        <img
          src="./chicken.png"
          className="popup-img grow"
          alt="chicken prompting joining our affiliate program"
        ></img>
      </a>
    </div>
  </div>
);
}