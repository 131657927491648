import React from "react";
import CircProgress from "./CircProgress";
import './ImpactResult.css';

import { useScreenshot, createFileName } from "use-react-screenshot";

const ImpactResult = ({impactData}) => {
    if (!impactData) {
        return null;
    }

    return (
        <div className="dynamic-progress">
            <div className="progress-gauge-container">
                <div className="progress-gauge-left">
                    <CircProgress
                        prog={impactData.carbon}
                        color="rgb(12,109,157)"
                        pic="./carbon.png"
                    />
                </div>
                <div className="text-right">
                    By purchasing our eco-friendly product, the equivalent of <span className="text-hightlight">
                    {impactData.totalCarsOffRoad} chickens are saved </span>each year. That's because Ulivit products are manufactured with
                    sustainability in mind, using processes that save lifes of our cute animal friends.
                </div>
            </div>
            <div className="progress-gauge-container">
                <div className="text-left">
                    Incorporating our product into your daily routine can help <span className="text-hightlight">reduce your LDL ("bad") cholesterol levels by up to {impactData.totalCholesterolSaved} percent</span>.
                    Our product is formulated with ingredients that support healthy cholesterol levels already within
                    the normal range, so you can take a proactive step towards supporting your heart health and overall
                    well-being.
                </div>
                <div className="progress-gauge-right">
                    <CircProgress
                        prog={impactData.cholesterol}
                        trailColor="rgb(77,39,93)"
                        pic="./Cholesterol.png"
                    />
                </div>
            </div>
            <div className="progress-gauge-container">
                <div className="progress-gauge-left">
                    <CircProgress
                        prog={impactData.land}
                        trailColor="rgb(70,178,68)"
                        pic="./land.png"
                    />
                </div>
                <div className="text-right">
                    You already helped <span className="text-hightlight">save {impactData.totalLandUse} of land
                    usage</span>, thanks to our commitment to innovation and sustainable production methods.
                    By choosing our product, you're making a positive impact on the environment and
                    supporting a more sustainable future.
                </div>
            </div>
            <div className="progress-gauge-container">
                <div className="text-left">
                    Congradulations! You just helped <span
                    className="text-hightlight">reduce food waste by {impactData.totalFightingFoodWaste}</span>. Our
                    commitment to sustainability and efficient production methods ensure that we are doing our part to
                    minimize waste and protect the environment.
                </div>
                <div className="progress-gauge-right">
                    <CircProgress
                        prog={impactData.food}
                        color="rgb(246,73,53)"
                        pic="./Reduce_Food_Waste_1.png"
                    />
                </div>
            </div>
            <div className="progress-gauge-container">
                <div className="progress-gauge-left">
                    <CircProgress
                        prog={impactData.water}
                        color="rgb(153,54,113)"
                        pic="./Water_Saved.png"
                    />
                </div>
                <div className="text-right">
                    Thanks to your commitment to sustainable production practices, this order has already helped <span
                    className="text-hightlight">save {impactData.totalWaterSaved} of water</span>. We're dedicated to
                    reducing our environmental footprint and helping our customers do the same. Thank you for
                    considering our product!
                </div>
            </div>
        </div>
    );
};

export default ImpactResult;
