import "./MatricesList.css";
import React from "react";
import Navbar from "../components/AdminNavbar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import AddMatrix from "./AddMatrix";
import axiosInstance from "../components/axiosConfig";
import {DeleteOutline} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";



export default function MatricesList() {
  const [seen, setSeen] = useState(false);
  const togglePop = () => {
    setSeen(!seen);
  };
  useEffect(() => {
    if (!seen) {
      document.body.style.opacity = 10;
    } else {
      document.body.style.opacity = 10;
    }
  }, [seen]);
  /*set up datastate*/
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/climate-measure`)
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, []);

  /*delete handler */
  const handleDelete = (id, e) => {
    if (window.confirm("Are you sure?")) {
      e.preventDefault();
      axiosInstance
        .delete(`/climate-measure/id=${id}`)
        .then((res) => console.log("deleted", res))
        .catch((err) => console.log(err));
    }
  
    const updateditems = data.filter((elem) => {
      return id !== elem.id;
    });
  
    setData(updateditems);
  };
  

  const columns = [
    { field: "sku", headerName: "Product SKU", width: 180, flex: 1 },

    { field: "waterSaved", headerName: "Water saved (Litres)", width: 180, flex: 1 },
    {
      field: "carsOffRoad",
      headerName: "Chicken Saved (Per Egg)",
      width: 180,
      flex: 1,
    },
    {
      field: "landUse",
      headerName: "Land Use Saved (Metres Squared)",
      width: 120,
      flex: 1,
    },
    {
      field: "fightingFoodWaste",
      headerName: "Food Waste Saved (Grams)",
      width: 120,
      flex: 1,
    },
    {
      field: "cholesterolSaved",
      headerName: "Cholesterol Saved (Milligram)",
      width: 180,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          /*unfinished part*/

          <>
            <DeleteOutline
              className="userListDelete"
              onClick={(e) => handleDelete(params.row.id, e)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="mat-list">
      <Navbar />
      {/* <h1 className="title">Our Matrices of Product</h1> */}
      <div onClick={() => togglePop()}>
        <button className="btn">CREATE</button>
      </div>
      {seen ? <AddMatrix toggle={() => togglePop()} /> : null}
      <div className="productList">
        <DataGrid
          style={{ height: "100%", width: "100%" }}
          autoHeight
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
          checkboxSelection
        />
      </div>
    </div>
  );
}