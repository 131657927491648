import "./AdminLogin.css";
import { Link, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./components/AuthContext";
import axiosInstance from "./components/axiosConfig";
function AdminLogin() {
  const [redirect, setRedirect] = useState(false);
  const {login, user} = useAuth();


  //To DO: Add a check to see if the user is already logged in
  //If they are, redirect them to the admin page
  // Store a better field than username in context
  // Change Alert boxes to more elegant solution, potentially a toast
  const handleLogin =async () => {
    let username = document.getElementById("usnm"); //.value;
    let password = document.getElementById("pswd"); //.value;
    if (username == null || password == null) {
      return;
    }
    username = username.value;
    password = password.value;
    
    try{
      const response = await axiosInstance.post(`/admin/login`,
          {
            username: username,
            password: password
        });
      if(response.data.authResult === "Authentication Successful"){
        //We are authenticated and will redirect to the admin page
        login(username);
        setRedirect(true);
      }else{
        //We are not authenticated and will not redirect to the admin page
        alert("Incorrect username or password");
      }
    }catch(e){
      //We are not authenticated and will not redirect to the admin page
      if(username === "admin"){//temporary override for testing
        login(username);
        setRedirect(true);
      }else{
        alert("Incorrect username or password");
      }
      
    }
  };
  //Automatically redirect if alreayd logged in
  useEffect(() => {
    console.log("User: " + user);
    if(user === true){
      setRedirect(true);
    }
  }, []);

  const handleEnterClick = (e) => {
    console.log(e.key)
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="adminlogin">
      <div className="admin-wrap">
        <div className="adminlogin-container">
          {redirect ? <Navigate to="/admin/matrices" /> : null}
          <img src="./favicon.ico" alt="logo" className="admin-logo-img"></img>
          <h1 className="pt-4 text-3xl text-center font-roadrage tracking-normal">Admin Login</h1>
          
          <div className="form-outline">
            <input
              className="input"
              type="text"
              name="uname"
              id="usnm"
              required
            />
            <label className="form-label">Username</label>
          </div>

          <div className="form-outline">
            <input
              className="input"
              type="password"
              name="psw"
              id="pswd"
              onKeyDown={handleEnterClick}
              required
            />
            <label className="form-label">Password</label>
          </div>

          <div className="field is-clearfix">
            <button
              type="submit"
              className="adminlogin__signInButton"
              onClick={handleLogin}
            >
              Sign In
            </button>
          </div>

        </div>
      </div>
      
      <div className="foot">
        <Link to={`/`}>Return Home</Link>
        <Link to={`/admin-login`}>Admin Login</Link>
      </div>
    </div>
  );
}

export default AdminLogin;
