import Navbar from "../components/Navbar";
import React, { useState, useRef, Component } from "react";
import { useNavigate, Link } from "react-router-dom";
import Toast from "../components/Toast";
import axiosInstance from "../components/axiosConfig";

export default function AffiliateRegistration() {
  const navigate = useNavigate();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const organizationRef = useRef(null);
  const organizationTypeRef = useRef(null);
  const profilePicRef = useRef(null);

  const [organizationBorderColor, setOrganizationBorderColor] =
    useState("border-gray-200");
  const [emailBorderColor, setEmailBorderColor] = useState("border-gray-200");
  const [passwordBorderColor, setPasswordBorderColor] =
    useState("border-gray-200");
  const [toast, setToast] = useState(null);

  function validateOrganization() {
    if (!organizationRef.current.value) {
      setOrganizationBorderColor("border-red-400");
    } else if (organizationRef.current.value) {
      setOrganizationBorderColor("border-gray-200");
    }
  }

  function validateEmail() {
    if (!emailRef.current.value) {
      setEmailBorderColor("border-red-400");
    }
    // also highlight the border if email is not in valid email format
    else if (
      !emailRef.current.value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailBorderColor("border-red-400");
    } else if (emailRef.current.value) {
      setEmailBorderColor("border-gray-200");
    }
  }

  function validatePassword() {
    if (!passwordRef.current.value) {
      setPasswordBorderColor("border-red-400");
    } else if (passwordRef.current.value) {
      setPasswordBorderColor("border-gray-200");
    }
  }

  function validateProfilePicture(event) {
    profilePicRef.current = event.target.value;
  }

  async function checkDuplicateEmail() {
    let validation;
    await axiosInstance
      .get(`/affiliate/validate/email=${emailRef.current.value}`)
      .then((response) => {
        let statusCode = response.status;
        validation = true;
      })
      .catch((error) => {
        let statusCode = error.response.status;
        // if coming back 404 means no duplicate records found, insert into our database
        if (statusCode === 404) {
          const errorToast = (
            <Toast errorMessage="Affiliate with the same email found" />
          );
          setToast(errorToast);
          // automatically close the toast
          setTimeout(() => {
            setToast(null);
          }, 7000);
          validation = false;
        }
        // if any other error, raise a toast
        else if (statusCode === 500) {
          const errorToast = <Toast errorMessage="Internal Server Error" />;
          setToast(errorToast);
          // automatically close the toast
          setTimeout(() => {
            setToast(null);
          }, 7000);
          validation = false;
        } else {
          const errorToast = (
            <Toast errorMessage="Oops! There is something wrong!" />
          );
          setToast(errorToast);
          // automatically close the toast
          setTimeout(() => {
            setToast(null);
          }, 7000);
          validation = false;
        }
      });
    return validation;
  }

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function handleRegistration() {
    if (!organizationRef.current.value) {
      return false;
    }
    let profPhoto = 1;
    if (typeof profilePicRef.current !== "object") {
      if (profilePicRef.current.includes("2")) {
        profPhoto = 2;
      } else if (profilePicRef.current.includes("3")) {
        profPhoto = 3;
      } else {
        profPhoto = 1;
      }
    }

    checkDuplicateEmail().then((response) => {
      let data = {
        promoCode: makeid(6).toUpperCase(),
        organizationName: organizationRef.current.value,
        emailAddress: emailRef.current.value,
        typeId: organizationTypeRef.current.value,
        profilePhoto: profPhoto,
        password: "password",
      };
      console.log(data);
      if (response) {
        axiosInstance
          .post(`/affiliate`, data)
          .then((response) => {
            navigate("/thankyou");
          })
          .catch(() => {
            console.log("something went wrong");
          });
      }
    });
  }
  return (
    <div id="affiliateRegistration" className="affiliateRegistration">
      <Navbar />
      {/*component*/}
      <body className="font-mono bg-ulivit-background-teal">
        {/*container*/}
        <div className="container mx-auto">
          <div className="flex justify-center">{toast}</div>
          <div className="flex justify-center px-6 pt-20">
            {/*row*/}
            <div className="w-full xl:w-3/4 lg:w-11/12 flex">
              {/*col*/}
              <div
                  className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg bg-sign-in-background"

              >
              </div>
              {/*col*/}
              <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
                <h3 className="pt-4 text-3xl text-center font-roadrage tracking-normal">
                  Join Us to Change the World!
                </h3>
                <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                  <div className="mb-4 md:justify-between">
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-sm font-bold text-gray-700"
                        htmlFor="organizationRef"
                      >
                        Name
                      </label>
                      <input
                        className={`w-full px-3 py-2 text-sm leading-tight text-gray-700 border ${organizationBorderColor} rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
                        id="organizationName"
                        type="text"
                        placeholder="Organization Name"
                        ref={organizationRef}
                        onChange={validateOrganization}
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="orgType">Group Type:</label>
                      <select
                        id="orgType"
                        name="orgType"
                        className="border-black border-5"
                        ref={organizationTypeRef}
                      >
                        <option value="b2114563-b0d6-4f9e-aa3f-b0a9a6c8ba37">
                          Individual
                        </option>
                        <option value="88bff64c-6234-4096-9003-dcbca5dc9288">
                          Organization
                        </option>
                        <option value="aaba8ba3-4a34-4a42-bc26-36ac2bafca56">
                          Team
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-4">
                    <fieldset className="flex flex-row justify-evenly">
                      <legend>Profile Picture:</legend>

                      <div>
                        <input
                          type="radio"
                          id="profileChoice1"
                          name="profileChoice"
                          value="profileChoice1"
                          className="not-none"
                          ref={profilePicRef}
                          onChange={validateProfilePicture}
                          defaultChecked={true}
                        />
                        <img
                          src="./profileicons/Industry_icon.png"
                          alt="women-hugging-earth"
                          width={"50px"}
                        />
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="profileChoice2"
                          name="profileChoice"
                          value="profileChoice2"
                          className="not-none"
                          ref={profilePicRef}
                          onChange={validateProfilePicture}
                        />
                        <img
                          src="./profileicons/Team_fundraiser.png"
                          alt="gaia-mother-earth-cartoon"
                          width={"50px"}
                        />
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="profileChoice3"
                          name="profileChoice"
                          value="profileChoice3"
                          className="not-none"
                          ref={profilePicRef}
                          onChange={validateProfilePicture}
                        />
                        <img
                          src="./profileicons/patron_icon_2.png"
                          alt="earth with hands shaking"
                          width={"50px"}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="mb-1.5">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className={`w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border ${emailBorderColor} rounded shadow appearance-none focus:outline-none focus:shadow-outline`}
                      id="email"
                      type="email"
                      placeholder="Email"
                      ref={emailRef}
                      onChange={validateEmail}
                    />
                  </div>
                  <div className="mb-6 text-center">
                    <button
                      className="w-full px-4 py-2 font-bold text-white bg-ulivit-background-teal rounded-full hover:bg-teal-400 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleRegistration}
                    >
                      Become An Affiliate Now!
                    </button>
                  </div>
                  {/*<hr className="mb-6 border-t" />*/}
                  {/* <div className="text-center">
                                    <Link
                                        className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                        to= {`/forgot-password`}
                                    >
                                        Forgot Password?
                                    </Link>
                                </div> */}
                  {/*<div className="text-center">*/}
                  {/*  <Link*/}
                  {/*    className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"*/}
                  {/*    to={`/affiliate-login`}*/}
                  {/*  >*/}
                  {/*    Already have an account? Login!*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                </form>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

