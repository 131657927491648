import "./AdminGlobal.css";

import React, { useEffect, useState } from "react";
import Navbar from "./components/AdminNavbar";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "./components/axiosConfig";

export default function AdminGlobal() {
  const [data, setData] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    let newData = [];
    try {
      let response = await axiosInstance.get("/global_stats");
      let fetchedResponse = response.data;
      fetchedResponse["id"] = 0;
      fetchedResponse["type"] = "GOALS";
      newData.push(fetchedResponse);
      response = await axiosInstance.get("/global_stats/progress");
      fetchedResponse = response.data;
      fetchedResponse["id"] = 1;
      fetchedResponse["type"] = "PROGRESS";
      newData.push(fetchedResponse);
      setData(newData);
      setLoading(false);
    } catch {
      console.log("Error");
    }
  };

  const handleEdit = async (params) => {
    if (params.id === 1) {
      return;
    }

    let newData = data[0];
    newData[params.field] = params.value;

    setData([newData, ...data.slice(1)]);
    try {
      let res = await axiosInstance.post("/global_stats", newData);
    } catch {
      console.log("Error");
    }
  };

  const columns = [
    {
      field: "type",
      headerName: "Type",
      width: 180,
      flex: 1,
    },
    {
      field: "carbonGoal",
      headerName: "Chicken Goal (Per Egg)",
      width: 180,
      flex: 1,
      editable: true,
    },
    {
      field: "cholosterolGoal",
      headerName: "Cholesterol Goal (Milligram)",
      width: 120,
      flex: 1,
      editable: true,
    },
    {
      field: "landGoal",
      headerName: "Land Goal (Metres Squared) ",
      width: 120,
      flex: 1,
      editable: true,
    },
    {
      field: "wasteGoal",
      headerName: "Food Waste Goal (Litres)",
      width: 180,
      flex: 1,
      editable: true,
    },
    {
      field: "waterGoal",
      headerName: "Water Goal (Grams)",
      width: 180,
      flex: 1,
      editable: true,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="global-container">
      <Navbar />
      {/* <div onClick={updateGlobal} id="btnContainer">
        <button className="btn">Update</button>
      </div> */}
      {/* {seen ? <AddMatrix toggle={() => togglePop()} /> : null} */}
      <span id="instructions">TO EDIT A FIELD DOUBLE CLICK ON THAT CELL</span>
      <div className="productList">
        <DataGrid
          style={{ height: "100%", width: "100%" }}
          autoHeight
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
          onCellEditCommit={(params) => handleEdit(params)}
          loading={loading}
          //   editRowsModel={editableRows}
          //   onRowClick={(params) => handleEdit(params.id)}
          //   onRowSelected={(params) => handleEdit(params.data.id)}
          //   onCellFocusOut={(params) => console.log(params)}
          //   onCellBlur={(params) => handleSave(params)}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
