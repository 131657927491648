import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import "./AffiliateTransactionList.css";

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', width: 100 },
  { field: 'lastName', headerName: 'Last Name', width: 100 },
  { field: 'email', headerName: 'Email', width: 150 },
  { field: 'carsOff', headerName: 'Cars off road', width: 150},
  { field: 'foodWaste', headerName: 'Food waste', width: 150},
  { field: 'waterSaved', headerName: 'Water saved', width: 150},
  { field: 'landSaved', headerName: 'Land saved', width: 150},
  { field: 'cholesterolSaved', headerName: 'Cholesterol saved', width: 150}
];

export default function AffiliateTransactionList() {
  const [gridData, setGridData] = useState([])

  return (
      <div className='whole'>
        <h1 class="pt-4 text-3xl text-center font-roadrage tracking-normal">Let's see how much impact we have</h1>
        <br />
          <div className="affiliateTransactionList bg-white" style={{ height: 750, width: '99%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid rows={gridData} columns={columns} />
                </div>
            </div>
          </div>
      </div>
  );
}
