import "./Navbar.css";

import { Link } from "react-router-dom";
export default function Navbar() {
  const openHamMenu = () => {
    //document.getElementsByClassName("nav-links")[0].classList.add("pop-out");
    if (
      document
        .getElementsByClassName("nav-links")[0]
        .classList.contains("pop-out")
    ) {
      document
        .getElementsByClassName("nav-links")[0]
        .classList.remove("pop-out");
    } else {
      document.getElementsByClassName("nav-links")[0].classList.add("pop-out");
    }
  };
  return (
    <div className="nav-main">
      <div className="nav-logo">
        <Link to={`/`}>
          <img
            src="./../logo.png"
            className="log-n"
            alt="uliviit logo(plant based protein)"
          ></img>
        </Link>
      </div>
      <div className="nav-links">
        <Link to={`/`}>Calculator</Link>
        {/*Maybe make this link to the leaderboard*/}
        <Link to={`/leaderboard`}>Leaderboard</Link>
        <a href={`https://www.ulivit.ca/shop`}>Shop</a>
        <a href={`https://www.ulivit.ca/`}>About</a>
      </div>
      <div className="social-links">
        <Link to={`/affiliate-registration`}>Join Us</Link>
        <a href="https://www.instagram.com/ulivit/">
          <img
            src="./instagram-2.png"
            className="social"
            alt="insta-logo"
          ></img>
        </a>
      </div>
      {/* <object
        data="./ham.svg"
        width="30"
        height="30"
        className="nav-ham"
        onClick={openHamMenu}
      >
        {" "}
      </object> */}
      <svg
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        width="30px"
        height="30px"
        className="nav-ham"
        onClick={openHamMenu}
      >
        <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" />
      </svg>
    </div>
  );
}
