import Navbar from "../components/Navbar";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function ThankYou() {
  return (
    <div className="thankYou">
      <Navbar />
      {/*component*/}
      <body className="font-mono bg-ulivit-background-teal">
        {/*container*/}
        <div className="container mx-auto">
          <div className="flex justify-center px-6 pt-20">
            {/*row*/}
            <div className="w-full xl:w-3/4 lg:w-11/12 flex">
              {/*col*/}
              <div
                className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
                style={
                  //this needs to be updated to use images provided by Ulivit
                  {
                    backgroundImage:
                      "url(https://images.unsplash.com/photo-1511690656952-34342bb7c2f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80)",
                  }
                }
              ></div>
              {/*col*/}
              <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
                <h1 className="pt-4 text-6xl text-center font-roadrage tracking-normal">
                  Thank you for signing up.
                </h1>
                <br></br>
                <h3 className="pt-4 text-3xl text-center font-roadrage tracking-normal">
                  You'll receieve an approval email within the next 2 days.
                </h3>
                <br></br>
                <div className="mb-6 text-center">
                  <Link
                    className="w-full px-4 py-2 font-bold text-white rounded-full bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    to={`/leaderboard`}
                  >
                    Check out our existing leaderboard!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
