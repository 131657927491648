import React, { useState, useEffect } from "react";
import Navbar from "../components/AdminNavbar";
import axiosInstance from "../components/axiosConfig";
import { DataGrid } from "@mui/x-data-grid";

export default function AffiliatesList() {

  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    fetchAffiliates();
  }, []);

  const fetchAffiliates = async () => {
    try {
      const response = await axiosInstance.get(`/affiliate/`);
      const parsedData = response.data.map((affiliate) => ({
        id: affiliate.id,
        organizationName: affiliate.organizationName,
        promoCode: affiliate.promoCode,
        emailAddress: affiliate.emailAddress,
        affiliateStatus: affiliate.affiliateStatus.status,
        commission: affiliate.commission,
      }));
      setGridData(parsedData);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const handleAffiliateApproval = async (id) => {
    try {
      await axiosInstance.put(`/affiliate/approve`, { id });
      const updatedGridData = gridData.map((affiliate) =>
        affiliate.id === id
          ? { ...affiliate, affiliateStatus: "approved" }
          : affiliate
      );
      setGridData(updatedGridData);
      alert("Affiliate approved successfully!");
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const handleAffiliateDeletion = async (id) => {
    try {
      await axiosInstance.delete(`/affiliate/id=${id}`);
      const updatedGridData = gridData.filter(
        (affiliate) => affiliate.id !== id
      );
      setGridData(updatedGridData);
      alert("Affiliate deleted successfully!");
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const handleNewPromoCode = async (id, newPromoCode) => {
    try {
      const affiliate = gridData.find((affiliate) => affiliate.id === id);
      if (affiliate) {
        await axiosInstance.put(`/affiliate/`, {
          id: affiliate.id,
          promoCode: newPromoCode,
          organizationName: affiliate.organizationName,
          emailAddress: affiliate.emailAddress,
        });
        const updatedGridData = gridData.map((affiliate) =>
          affiliate.id === id
            ? { ...affiliate, promoCode: newPromoCode }
            : affiliate
        );
        setGridData(updatedGridData);
        alert("Promo code updated successfully!");
      } else {
        alert("Affiliate not found.");
      }
    } catch (error) {
      handleAxiosError(error);
    }
  };
  
  

  const handleAxiosError = (error) => {
    let statusCode = error.response?.status || 500;
    const errorMessage =
      statusCode === 500
        ? "An Internal Server Error Happened, Please Check Console for More Detail"
        : error.message;
    console.error(error);
    alert(errorMessage);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "organizationName", headerName: "Organization Name", width: 200 },
    { field: "promoCode", headerName: "Promo Code", width: 200 },
    { field: "emailAddress", headerName: "Email Address", width: 200 },
    { field: "affiliateStatus", headerName: "Affiliate Status", width: 150 },
    { field: "commission", headerName: "Commission", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <>
            {params.row.affiliateStatus === "pending" && (
              <button
                className="block bg-blue-500 text-white p-3 rounded-xl font-bold mr-2"
                type="button"
                onClick={() => handleAffiliateApproval(id)}
              >
                Approve
              </button>
            )}
            <button
              className="block bg-red-500 text-white p-3 rounded-xl font-bold mr-2"
              type="button"
              onClick={() => handleAffiliateDeletion(id)}
            >
              Delete
            </button>
            <button
              className="block bg-green-500 text-white p-3 rounded-xl font-bold"
              type="button"
              onClick={() => {
                const newPromoCode = prompt("Please enter the new promo code:");
                if (newPromoCode) {
                  handleNewPromoCode(id, newPromoCode);
                }
              }}
            >
              New Promo Code
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="affiliateList">
      <Navbar />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={gridData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}
