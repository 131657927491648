import React, {useState, useEffect, createRef, useCallback} from "react";
import {useSearchParams} from "react-router-dom";
import ImpactResult from "./ImpactResult";
import {useNavigate} from "react-router-dom";
import {Icon, MenuItem, Select} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "./ImpactSearch.css";
import {createFileName, useScreenshot} from "use-react-screenshot";
import ShareIcon from '@mui/icons-material/Share';


const ImpactSearch = ({getImpactData}) => {
    const [inputValue, setInputValue] = useState("");
    const [impactData, setImpactData] = useState(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Create an instance of useNavigate
    const [searchType, setSearchType] = useState("orderId");
    const ref = createRef(null);

    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    const download = (image, { name = "img", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);


    useEffect(() => {
        const fetchData = async () => {
            const promoCode = searchParams.get("promoCode");
            const orderId = searchParams.get("orderId");

            if (promoCode || orderId) {
                const isPromoCode = !!promoCode;
                const value = promoCode || orderId;
                const data = await getImpactData(isPromoCode, value);
                setImpactData(data);
            }
        };

        fetchData();
    }, [searchParams, getImpactData]);

    useEffect(() => {
        if (impactData) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[impactData])

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isPromoCode = searchType === "promoCode";
        const data = await getImpactData(isPromoCode, inputValue);
        setImpactData(data);

        console.warn('current selection is ', isPromoCode);
        // Update the URL with the input value as a query parameter
        if (isPromoCode) {
            // If inputValue is a promo code
            navigate(`/?promoCode=${inputValue}`);


        } else {
            // If inputValue is an order id
            navigate(`/?orderId=${inputValue}`);

        }
    };

    return (
        <div className="impact-search">
            <div className="search-section">
                <form onSubmit={handleSubmit}>
                    <label className="input-search-text" htmlFor="inputValue">Check How Much Impact You Made!</label>
                    <br/>
                    <div className="input-search-container">
                        <Select className="input-search-dropdown"
                                value={searchType}
                                onChange={(e) => setSearchType(e.target.value)}>
                            <MenuItem className="dropdown-value" value="orderId">Order ID</MenuItem>
                            <MenuItem className="dropdown-value" value="promoCode">Promo Code</MenuItem>
                        </Select>
                        <input
                            type="text"
                            id="inputValue"
                            className="input-search-bar"
                            value={inputValue}
                            onChange={(event) => setInputValue(event.target.value)}
                            placeholder="Enter a promo code or order ID"
                        />
                        <button className="searchButton" type="submit">
                            <SearchIcon></SearchIcon>
                        </button>
                    </div>
                </form>
            </div>
            <div className="impact-result" ref={ref}>
                {(() => {
                    let button = null;
                    if (impactData) {
                        button = (
                            <button className="share-button" onClick={downloadScreenshot}>
                                <ShareIcon></ShareIcon>
                            </button>
                        );
                    }
                    return button;
                })()}
                <ImpactResult impactData={impactData}/>
            </div>
        </div>
    );
};

export default ImpactSearch;
