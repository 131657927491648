import React from 'react'


export default function Toast(props){
    return (
        // https://img.freepik.com/free-vector/oops-explosion-vector_53876-17099.jpg?w=826&t=st=1668597051~exp=1668597651~hmac=4d5a6f05d3e653557c75f730169bc71b9f217e2c06a5ef3fb1f59520095db439
        <div className="errorToast">
            <div className="container w-1/5 border-black border-2 object-center bg-white absolute p-3 ml-6 rounded-xl">
                {/*col*/}
                <div>
                    <div id="errorMessage" className="m-2 pl-9 text-l leading-tight text-red-400 font-bold font-mono">
                        {props.errorMessage}
                    </div>
                </div>
            </div>
        </div>
    )
}